import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import Layout from "../../atoms/Layout/Layout";
import ContactForm from "../ContactForm/ContactForm";
import Footer from "../Footer/Footer";
import "./ProjectPage.scss";

export default function ProjectPage() {
  return (
    <Layout sub>
      <div className="inner">
        <div className="ProjectPage">
          <div className="title">
            <div className="backbutton" onClick={() => window.history.back()}>
              <FaAngleLeft></FaAngleLeft>
            </div>
            FFB Forschungszentrum
          </div>
          <div className="sub">2022 | Frauenhofer | Digitale Industrie</div>
          <div className="hero">
            <img alt="hero" src="projects/p1.png"></img>
          </div>
          <div className="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
            vel quaerat voluptatibus sint, fugiat nemo obcaecati iusto quo natus
            optio laudantium rem saepe eveniet ducimus eum nostrum velit ut
            fuga. Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Voluptas vel quaerat voluptatibus sint, fugiat nemo obcaecati iusto
            quo natus optio laudantium rem saepe eveniet ducimus eum nostrum
            velit ut fuga. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Voluptas vel quaerat voluptatibus sint, fugiat nemo obcaecati
            iusto quo natus optio laudantium rem saepe eveniet ducimus eum
            nostrum velit ut fuga.
          </div>
        </div>
      </div>
      <div className="orangeBar"></div>
      <ContactForm></ContactForm>
      <Footer></Footer>
    </Layout>
  );
}
