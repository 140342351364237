import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import Brille from "../../3d/Brille";
import ContactForm from "../../atoms/ContactForm/ContactForm";
import Footer from "../../atoms/Footer/Footer";
import Layout from "../../atoms/Layout/Layout";
import Spacer from "../../atoms/Spacer/Spacer";
import "./Home.scss";

export default function Home() {
  return (
    <Layout className="Home">
      <div className="fullBox">
        <video autoPlay={true} muted loop id="myVideo" src={"/bg.mp4"}></video>
        <div className="left">
          <div className="bigText">
            Ihr High End Dienstleister für{" "}
            <span className="orange">Augmented, Virtual</span> und{" "}
            <span className="orange">Mixed Reality</span> Erfahrungen<br></br>
            <div className="smallHeaderText">
              Lemonverse ist eine Ideenschmiede aus Münster und verändert mit
              Hilfe von Mixed-Reality und Web-Technologien die Art und Weise,
              wie Wissen vermittelt wird.
            </div>
            <div className="flex homeButtons">
              <Link to="/demo" className="demoButton">
                Demo buchen
              </Link>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="vrHolder">
            <Canvas
              style={{ minWidth: 0 }}
              flat
              linear
              camera={{ fov: 45, position: [3, 2, 0] }}
              className="vrCanvas"
            >
              <Brille scale={8} rotation={[0.1, -1, 0]}></Brille>
              <pointLight
                color="red"
                intensity={4}
                decay={2}
                position={[
                  0.5050320879048265, 0.13444937375087984, 0.16872778851932818,
                ]}
              />
              <pointLight
                color="blue"
                intensity={4}
                decay={2}
                position={[
                  -0.3917582405235437, -0.0039830097774178255,
                  -0.12367266007491731,
                ]}
              />

              <OrbitControls
                enableZoom={false}
                enablePan={false}
                autoRotate
                autoRotateSpeed={-1}
              ></OrbitControls>
              <ambientLight intensity={1}></ambientLight>
              <directionalLight position={[-10, 5, -10]}></directionalLight>
              <directionalLight
                intensity={0.2}
                color="#fec601"
                position={[-10, 5, -10]}
              ></directionalLight>
              <directionalLight
                color="#fec601"
                intensity={0.5}
                position={[10, 5, 10]}
              ></directionalLight>
              <directionalLight
                intensity={2}
                position={[10, 5, 10]}
              ></directionalLight>
            </Canvas>
          </div>
        </div>
        <a href={"/#industrie"} className="scrollDownButton">
          <FaAngleDown></FaAngleDown>
        </a>
      </div>
      <div className="partnerLogoGroup">
        <div className="partnerLogos">
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/wh.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/mondi.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/asbeck.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/lwl.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/lbs.png"></img>
          </div>
        </div>
        <div className="partnerLogos">
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/reach.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/innofact.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/beventum.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/sprind.png"></img>
          </div>
          {/*
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/asbeck.png"></img>
          </div>
          */}
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/digihub.png"></img>
          </div>
        </div>
      </div>

      <Banner
        title="Industrie"
        linkTarget={"/industrie"}
        image="iStock-1156535018.jpg"
        text="Der Einsatz von Virtual Reality (VR) nimmt in der Industrie zu, da sie eine effektive Möglichkeit bietet, Schulungen und Simulationen für Mitarbeiter durchzuführen und den Arbeitsablauf zu optimieren."
        linkText={"Mehr erfahren"}
        anchor="industrie"
        nextAnchor={"museum"}
      ></Banner>

      <Banner
        title="Museum"
        left
        linkTarget={"https://avemus.de"}
        external
        image="iStock-1324782258.jpg"
        text="Der Einsatz von VR im Museum ermöglicht es Besuchern, auf interaktive und immersiv Weise Geschichte und Kunst zu erleben."
        linkText={"Mehr erfahren"}
        anchor="museum"
        nextAnchor={"marktforschung"}
      ></Banner>

    
      <a className="hiddenAnchor teamAnchor" href="/#" name={"team"}>
        #
      </a>
      <div className="teamArea">
        <div className="title">Die Zitronauten des Lemonverse</div>
        <div className="members">
          <div className="member">
            <img alt="Team Member" src="/david.jpg"></img>
            <div className="memberName">David Akopyan</div>
            <div className="memberRole">COO und VR Experte</div>
          </div>
          <div className="member">
            <img alt="Team Member" src="/domkiss.jpg"></img>
            <div className="memberName">Dominik Kissmann</div>
            <div className="memberRole">CEO und Marketing Genie</div>
          </div>
          <div className="member">
            <img alt="Team Member" src="/willi.png"></img>
            <div className="memberName">Willi Krappen</div>
            <div className="memberRole">CTO und Javascript Ninja</div>
          </div>
        </div>

        <div className="sub">
          Die Lemonverse GmbH ist ein junges Unternehmen aus Münster, das sich
          auf die Entwicklung von VR-Lösungen spezialisiert hat. Wir sind ein
          Team von 3 Entwicklern, die sich auf die Entwicklung von VR-Lösungen
          spezialisiert haben. Unsere Vision ist es, die Welt zu einem besseren
          Ort zu machen, indem wir VR-Lösungen für Unternehmen entwickeln, die
          die Welt verändern.
          <br></br>
          <br></br>
          Wir freuen uns auf die Zusammenarbeit mit Ihnen!
        </div>
      </div>

      <ContactForm></ContactForm>
      <Footer></Footer>
    </Layout>
  );
}

export function Banner({
  title,
  text,
  linkText,
  linkTarget,
  left,
  anchor,
  nextAnchor,
  image,
  external,
  half,
  third,
}) {
  return (
    <>
      <a className="hiddenAnchor" href="/#" name={anchor}>
        #
      </a>
      <div
        className={
          "fullImageBox " +
          (left ? "left" : "") +
          (half ? "half" : "") +
          (third ? "third" : "")
        }
        style={{ backgroundImage: "url(/" + image + ")" }}
      >
        <div className="boxText">
          <div className="largeBoxText">{title}</div>
          <div className="subBoxText">{text}</div>
          {linkText && linkTarget && (
            <>
              <Spacer h={24}></Spacer>
              {external ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="readMoreLink"
                  href={linkTarget}
                >
                  {linkText}
                </a>
              ) : (
                <Link className="readMoreLink" to={linkTarget}>
                  {linkText}
                </Link>
              )}
            </>
          )}
        </div>

        {nextAnchor && (
          <a href={"/#" + nextAnchor} className="scrollDownButton">
            <FaAngleDown></FaAngleDown>
          </a>
        )}
      </div>
    </>
  );
}
