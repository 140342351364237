/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Windrad({ speed = 1, ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/windrad.glb");
  const { actions } = useAnimations(animations, group);
  useEffect(() => {
    actions["Object020Action"].play();
  }, [actions]);
  useEffect(() => {
    actions["Object020Action"].setEffectiveTimeScale(speed);
  }, [speed, actions]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group
        name="Industrial_wind_turbine_"
        position={[0.0485439487, 49.8035469055, 0.4092504978]}
        scale={0.001}
      >
        <mesh
          name="Object026"
          geometry={nodes.Object026.geometry}
          material={nodes.Object026.material}
          position={[-48.5439453125, 19246.01953125, -409.2504577637]}
          scale={1.2418749332}
        />
      </group>
      <mesh
        name="Object020"
        geometry={nodes.Object020.geometry}
        material={nodes.Object020.material}
        position={[0, 70.7059249878, 2.8077960014]}
        rotation={[-Math.PI / 2, -Math.PI / 3, Math.PI]}
        scale={[0.0019471293, 0.0019471291, 0.0019471293]}
      />
      <mesh
        name="Cube"
        geometry={nodes.Cube.geometry}
        material={materials.m2}
        position={[0, -0.0558233261, 0]}
        scale={8.8581485748}
      />
    </group>
  );
}

useGLTF.preload("/windrad.glb");
