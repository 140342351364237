import React from "react";

import ProjectPage from "../../atoms/ProjectPage/ProjectPage";
import "./FFB.scss";

export default function FFB() {
  return (
      <ProjectPage></ProjectPage>
  );
}
