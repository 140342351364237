import React, { useRef } from "react";
import "./Advantages.scss";

export default function Advantages() {
  const advantages = [
    {
      title: "Innovativ",
      text: "Durch einzigartige Möglichkeiten begeistern Lemonverse VR-Trainings zukünftige Aufgaben zu erlernen. Unsere Trainings schaffen mit neuesten Technologien und kreativen Ideen Inspiration und Faszination bei Ihren Mitarbeitern.",
    },
    {
      title: "Hands-On",
      text: "Ihre Mitarbeiter können in Lemonverse VR-Trainings ihre Fähigkeiten in einer virtuellen Umgebung praktisch anwenden. Durch interaktive Aufgaben und realitätsnahe Simulationen fördern wir praktisches Lernen und bieten eine risikofreie Umgebung zum Verbessern von Fähigkeiten und Wissen.",
    },
    {
      title: "Interaktiv",
      text: "Lemonverse VR-Trainings bieten eine interaktive Lernumgebung, in der Ihre Mitarbeiter aktiv eingebunden werden. Entscheidungen treffen und Aktionen ausführen fördert das Verständnis und die Anwendung von Lerninhalten.",
    },
    {
      title: "Intuitiv",
      text: "Unsere VR-Trainings sind intuitiv bedienbar, mit einer nutzerfreundlichen Oberfläche und klaren Anweisungen. Wir bieten ein reibungsloses Lernerlebnis, ohne zusätzliche Schulungen zu benötigen.",
    },
    {
      title: "Maßgeschneidert",
      text: "Wir erstellen individuelle Lernmodule, die auf die Bedürfnisse Ihres Unternehmens und Ihrer Mitarbeiter zugeschnitten sind. Lemonverse VR-Trainings werden speziell auf die Anforderungen Ihrer Branche und Ihrer Mitarbeiter abgestimmt.",
    },
    {
      title: "Parallel",
      text: "Lemonverse VR-Trainings können parallel zum Arbeitsalltag durchgeführt werden. Wir ermöglichen flexible Lernzeiten und standortunabhängige Nutzung, um es Ihren Mitarbeitern zu erleichtern, ihre Lerninhalte in ihren Arbeitsalltag zu integrieren.",
    },
    {
      title: "Mehrsprachig",
      text: "Um die Bedürfnisse einer globalen Belegschaft zu erfüllen, sind Lemonverse VR-Trainings in verschiedenen Sprachen verfügbar. Wir bieten Schulungen in verschiedenen Sprachen an, um sicherzustellen, dass jeder Mitarbeiter die gleichen Lerninhalte in seiner bevorzugten Sprache erhält.",
    },
    {
      title: "Wiederholbar",
      text: "Lemonverse VR-Trainings können so oft wiederholt werden, wie es notwendig ist, um das Lernziel zu erreichen. Durch mehrmaliges Durchlaufen der Lernmodule können Ihre Mitarbeiter ihr Verständnis vertiefen und ihr Wissen festigen.",
    },
    {
      title: "Kostengünstig",
      text: "Durch den Verzicht auf teure Reisekosten und die Möglichkeit, mehrere Mitarbeiter gleichzeitig zu schulen, sind Lemonverse VR-Trainings eine kosteneffiziente Alternative zu traditionellen Schulungen. Sie sparen Zeit und Kosten.",
    },
    {
      title: "Trackbar",
      text: "Lemonverse VR-Trainings sind trackbar, d.h. Sie können jederzeit den Lernfortschritt Ihrer Mitarbeiter verfolgen. Durch die Analyse von Daten und Statistiken können Sie die Effektivität des Trainings messen und gezielt Optimierungen vornehmen, um die Lernergebnisse zu verbessern.",
    },
  ];
  const advantageCount = advantages.length;

  const [selectedAdvantage, setSelectedAdvantage] = React.useState(0);
  //using useEffect, periodically increase the advantage variable
  const intervalTime = useRef(5000);
  React.useEffect(() => {
    let interval = setInterval(() => {
      setSelectedAdvantage((selectedAdvantage + 1) % advantageCount);
    }, intervalTime.current);
    intervalTime.current = 5000;
    return () => clearInterval(interval);
  }, [setSelectedAdvantage, advantageCount, selectedAdvantage]);
  return (
    <div className="Advantages section">
      <div className="inner">
        <div className="title">
          Mit unseren <span className="bold">Virtual Reality Trainings</span>
          <br></br>
          <span className="orange">lernen</span> Ihre Mitarbeiter:
        </div>
        <div className="line"></div>
        <div className="mobAdvantages">
          {advantages.map((advantage, index) => {
            return (
              <div className="advantage">
                <div className="title">{advantage.title}</div>
                <div className="text">{advantage.text}</div>
              </div>
            );
          })}

          <div className="advantage">
            <div className="title">innovativ</div>
            <div className="text">
              lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat sed diam voluptua. Lorem ipsum dolor sit amet, consetetur
              sadipscing elitr, sed diam
            </div>
          </div>
        </div>
        <div className="desktopAdvantages">
          <div className="list">
            {
              // map the advantages to divs
              advantages.map((advantage, index) => {
                return (
                  <div
                    onMouseOver={() => {
                      intervalTime.current = 10000;
                      setSelectedAdvantage(index);
                    }}
                    onClick={() => {
                      intervalTime.current = 10000;
                      setSelectedAdvantage(index);
                    }}
                    className={
                      "advantage " +
                      (selectedAdvantage % advantageCount === index
                        ? "active"
                        : "")
                    }
                  >
                    {advantage.title}
                  </div>
                );
              })
            }
          </div>
          <div className="text">
            "{advantages[selectedAdvantage % advantageCount].text}"
          </div>
        </div>
      </div>
    </div>
  );
}
