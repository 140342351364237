import React, { useEffect } from "react";
import Header from "../Header/Header";
import "./Layout.scss";

export default function Layout({ children, sub, className }) {
  useEffect(() => {
    if (sub) {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [sub]);
  return (
    <div className={"Layout " + className}>
      <Header sub={sub}></Header>
      {children}
    </div>
  );
}
