import React, { useRef } from "react";
import Spacer from "../Spacer/Spacer";
import "./Header.scss";

import { useSpring, animated } from "@react-spring/web";

import { IoMenu } from "react-icons/io5";
import {
  FaHome,
  FaIndustry,
  FaStarOfLife,
  FaUsers,
} from "react-icons/fa";

import { useDrag } from "@use-gesture/react";
import { Link } from "react-router-dom";

export default function Header({ sub }) {
  let headerRef = useRef();
  const navOpenWidth = 300;
  let [style, api] = useSpring(() => ({
    width: 0,
  }));
  useDrag(
    ({ movement, active }) => {
      api.start({ width: navOpenWidth - movement[0] });
      if (!active && movement[0] > 50) {
        api.start({ width: 0 });
      } else if (!active) {
        api.start({ width: navOpenWidth });
      }
    },
    { target: headerRef }
  );
  if (sub) {
    return (
      <>
        <div className="Header">
          <div className="inner">
            <Link to="/#top" className="logo">
              <img alt="logo" className="logoImg" src="/logo.png"></img>
            </Link>
            <div className="break"></div>
            <div className="nav">
              <Link to="/" className="navItem">
                Home
              </Link>

              <Link to="/demo" className="demoButton">
                Demo buchen
              </Link>
            </div>

            <div
              className="mobileNavButton"
              onClick={() => {
                api.start({ width: navOpenWidth });
              }}
            >
              <IoMenu size={30}></IoMenu>
            </div>
          </div>
        </div>
        <animated.div
          className="mobileNav"
          ref={headerRef}
          style={{ width: style.width, maxWidth: style.width }}
        >
          <Link to="/" className="navItem">
            Home
            <Spacer w={18}></Spacer>
            <FaHome></FaHome>
          </Link>

          <div className="navItem">
            <Link to="/demo" className="demoButton">
              Demo buchen
            </Link>
          </div>
        </animated.div>
      </>
    );
  }
  return (
    <>
      <div className="Header">
        <div className="inner">
          <a href="/#top" className="logo">
            <img alt="logo" className="logoImg" src="/logo.png"></img>
          </a>
          <div className="break"></div>
          <div className="nav">
            <a href="#industrie" className="navItem">
              Industrie
            </a>
            <a href="#museum" className="navItem">
              Museum
            </a>

            <a href="#team" className="navItem">
              Team
            </a>
            <Link to="/demo" className="demoButton">
              Demo buchen
            </Link>
          </div>
          <div className="languageSwitcher"></div>
          <div
            className="mobileNavButton"
            onClick={() => {
              api.start({ width: navOpenWidth });
            }}
          >
            <IoMenu size={30}></IoMenu>
          </div>
        </div>
      </div>

      <animated.div
        className="mobileNav"
        ref={headerRef}
        style={{ width: style.width, maxWidth: style.width }}
      >
        <a
          onClick={() => api.start({ width: 0 })}
          href="#industrie"
          className="navItem"
        >
          Industrie <Spacer w={18}></Spacer>
          <FaIndustry></FaIndustry>
        </a>
        <a
          onClick={() => api.start({ width: 0 })}
          href="#museum"
          className="navItem"
        >
          Museum <Spacer w={18}></Spacer>
          <FaStarOfLife></FaStarOfLife>
        </a>

        <a
          onClick={() => api.start({ width: 0 })}
          href="#team"
          className="navItem"
        >
          Team
          <Spacer w={18}></Spacer>
          <FaUsers></FaUsers>
        </a>

        <div className="navItem">
          <Link to="/demo" className="demoButton">
            Demo buchen
          </Link>
        </div>
      </animated.div>
    </>
  );
}
