import React from "react";
import PortfolioPage from "../../atoms/PortfolioPage/PortfolioPage";
import "./Marktforschung.scss";
import { FaChartLine, FaClock, FaMoneyBill, FaUser } from "react-icons/fa";
import Windrad from "../../3d/Windrad";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
export default function Marktforschung() {
  return (
    <PortfolioPage
      hero={"marktforschung.png"}
      text={
        <ol>
          <li>
            VR ermöglicht es, realistische und lebensechte Umgebungen zu
            simulieren und dadurch tiefergehende Einblicke in das Verhalten und
            die Reaktionen von Probanden zu gewinnen.
          </li>
          <li>
            VR erlaubt es, Marktforschung in einer kontrollierten und
            standardisierten Umgebung durchzuführen, was die Vergleichbarkeit
            und Aussagekraft der Ergebnisse verbessert.
          </li>
          <li>
            VR bietet die Möglichkeit, neue Produkte oder Dienstleistungen in
            einer realitätsnahen Umgebung zu testen und die Reaktionen der
            Probanden zu beobachten.
          </li>
          <li>
            VR erlaubt es, ungewöhnliche oder gefährliche Situationen zu
            simulieren, um die Reaktionen von Probanden in solchen Umgebungen zu
            untersuchen.
          </li>
          <li>
            VR ermöglicht es, die Reaktionen von Probanden in Echtzeit zu
            beobachten und zu analysieren, was die Ergebnisse der Marktforschung
            verbessert und die Entscheidungsfindung erleichtert.
          </li>
          <li>
            VR erlaubt es, die emotionale Verbindung von Probanden zu einem
            Produkt oder einer Dienstleistung zu untersuchen, indem deren
            nonverbale Reaktionen beobachtet werden.
          </li>
          <li>
            VR bietet die Möglichkeit, die Interaktionen von Probanden mit einem
            Produkt oder einer Dienstleistung in Echtzeit zu beobachten und
            dadurch tiefergehende Einblicke in deren Verhalten und Präferenzen
            zu gewinnen.
          </li>
          <li>
            VR erlaubt es, die Wirkung von Faktoren wie Farben, Gerüchen, Sounds
            und anderen Sinneseindrücken auf die Reaktionen von Probanden zu
            untersuchen.
          </li>
          <li>
            VR bietet die Möglichkeit, verschiedene Szenarien und Variationen
            von Produkten oder Dienstleistungen zu simulieren und die Reaktionen
            der Probanden direkt zu vergleichen.
          </li>
          <li>
            VR erlaubt es, Marktforschung in großem Maßstab und an verschiedenen
            Orten durchzuführen, ohne die Probanden tatsächlich an verschiedenen
            Orten beobachten zu müssen Dadurch können große Datenmengen schnell
            und effizient ausgewertet werden.
          </li>
        </ol>
      }
    >
      <div className="welcomeBlock">
        <div className="wbInner">
          <div className="right">
            <div className="icons">
              <div className="icon">
                <div className="iconImage">
                  <FaMoneyBill></FaMoneyBill>
                </div>
                <div className="iconText">Spart bis zu 50% der Kosten</div>
                <div className="iconSubText">
                  Der Einsatz von VR in der Marktschulung kann bis zu 50% der
                  Kosten traditioneller Marktforschungsansätze einsparen.
                </div>
              </div>
              <div className="icon">
                <div className="iconImage">
                  <FaClock></FaClock>
                </div>
                <div className="iconText">
                  Ermöglicht schnellere Datenerhebung
                </div>
                <div className="iconSubText">
                  VR-Technologie ermöglicht es, schneller und einfacher Daten zu
                  sammeln und zu analysieren, was zu schnelleren Entscheidungen
                  führen kann.
                </div>
              </div>
              <div className="icon">
                <div className="iconImage">
                  <FaUser></FaUser>
                </div>
                <div className="iconText">
                  Verbessert die Validität der Ergebnisse
                </div>
                <div className="iconSubText">
                  VR-Umgebungen können realistischere Simulationen von
                  Kaufszenarien und Produkten erstellen, was zu einer
                  verbesserten Validität der Ergebnisse führen kann.
                </div>
              </div>
              <div className="icon">
                <div className="iconImage">
                  <FaChartLine />
                </div>
                <div className="iconText">Ermöglicht tiefergehende Analyse</div>
                <div className="iconSubText">
                  VR-Technologie ermöglicht es, detailliertere Informationen
                  über Verbraucherverhalten und -präferenzen zu erfassen, was zu
                  einer tiefergehenden Analyse der Marktforschungsergebnisse
                  führen kann.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Canvas
        style={{ height: 450, background: "#222", pointerEvents: "none" }}
        camera={{ position: [0, 0, 150] }}
      >
        <directionalLight></directionalLight>
        <ambientLight></ambientLight>
        <OrbitControls
          enablePan={false}
          autoRotateSpeed={0.2}
          autoRotate
          minDistance={50}
          maxDistance={250}
        ></OrbitControls>
        <Windrad position={[0, -30, 0]} speed={1}></Windrad>
        <Stars
          noise={20}
          fade={1}
          rayleigh={false}
          count={1200}
          depth={1}
          saturation={1}
        ></Stars>
      </Canvas>
    </PortfolioPage>
  );
}
