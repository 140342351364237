import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="Footer">
      <div className="addressWrapper">
      <div className="address">
          Besuchen Sie uns vor Ort:<br></br>
          <br></br>
          <b>
            Lemonverse<br></br>
            Markweg 30<br></br>48147 Münster
          </b>
            <br></br>
            <br></br>
            <br></br>
          Oder rufen Sie uns an
          <br></br>
          <br></br>
          <b>+49 (0) 174 186 1175</b>
        </div>
        <MapBox></MapBox>
      </div>
      Copyright 2024 @ Lemonverse 
      <div className="footerLinks">
        <Link to="/impressum">Impressum</Link> |{" "}
        <Link to="/privacy">Datenschutzerklärung</Link>
      </div>
    </div>
  );
}

function MapBox() {
  let [accept, setAccept] = useState(false);
  return (
    <>
      {accept ? (
        <div className="frame">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9828.275251810863!2d7.6186962!3d51.9873994!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x28b83433c2c5d32f!2slemontree.xyz%20GbR!5e0!3m2!1sde!2sde!4v1658828477982!5m2!1sde!2sde"
            className="googleMap"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      ) : (
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="mapPreview"
            style={{
              objectFit: "contain",
            }}
            onClick={() => {
              setAccept(true);
            }}
            src="/map.png"
          ></img>
        </div>
      )}
    </>
  );
}
