import React from "react";
import { Link } from "react-router-dom";
import { Banner } from "../../screens/Home/Home";
import ContactForm from "../ContactForm/ContactForm";
import Footer from "../Footer/Footer";
import Layout from "../Layout/Layout";
import "./PortfolioPage.scss";

export default function PortfolioPage({ children, title, text, hero }) {
  return (
    <Layout className="PortfolioPage" sub>
      <Banner
        third
        title="Marktforschung"
        text="Wie wir mit unseren innovativen Lösungen Kunden in der Industrie helfen"
        image={hero || "uwe.png"}
        linkTarget="/"
      ></Banner>
      {children}
      {(title || text) && (
        <div className="textZone">
          {title && <div className="title">{title}</div>}
          {text && <div className="text">{text}</div>}
        </div>
      )}

      <div className="subTitle">Das Zitroversum</div>

      <div className="pastProjects">
        <div
          className="project"
          style={{ backgroundImage: "url(/projects/p1.png)" }}
        >
          <div className="projectInfo">
            <div className="projectName">VR Haus</div>
          </div>
        </div>
        <Link
          to="/project1"
          className="project"
          style={{ backgroundImage: "url(/projects/p2.png)" }}
        >
          <div className="projectInfo">
            <div className="projectName">Apokalypse Münsterland</div>
          </div>
        </Link>
        <div
          className="project"
          style={{ backgroundImage: "url(/projects/p3.png)" }}
        >
          <div className="projectInfo">
            <div className="projectName">Apokalypse Münsterland</div>
          </div>
        </div>
        <div
          className="project"
          style={{ backgroundImage: "url(/projects/p4.png)" }}
        >
          <div className="projectInfo">
            <div className="projectName">Apokalypse Münsterland</div>
          </div>
        </div>
      </div>
      <ContactForm></ContactForm>
      <Footer></Footer>
    </Layout>
  );
}
