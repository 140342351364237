import React from "react";
import ContactForm from "../../atoms/ContactForm/ContactForm";
import Footer from "../../atoms/Footer/Footer";
import Layout from "../../atoms/Layout/Layout";
import "./BookDemo.scss";

export default function BookDemo() {
  return (
    <Layout className="BookDemo" sub>
      <div className="inner">
        <ContactForm
          defaultMessage="Ich interessiere mich für eine Demo!"
          title={"Sie interessieren sich\nfür eine Demo?"}
          sub={
            "Kontaktieren Sie uns,\neine Mitarbeiter unseres\nTeams wird sich mit Ihnen\nin Verbindung setzen."
          }
          email={"demo@lemonverse.de"}
        ></ContactForm>
      </div>
      <Footer></Footer>
    </Layout>
  );
}
