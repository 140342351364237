import React from "react";
import { FaArrowsAltH, FaLongArrowAltRight } from "react-icons/fa";
import "./Workflow.scss";

export default function Workflow() {
  return (
    <div className="Workflow">
      <div className="workflowPart">
        <div className="num">01</div>
        <div className="title">Grundlagen ermitteln</div>
        <div className="text">
          In enger Zusammenarbeit mit den Wissensträgern ihres Unternehmens,
          sammeln wir im ersten Schritt Materialien und Ideen. Daraus erstellen
          wir ein Konzept, ein Storyboard sowie einen Projektplan passend für
          jedes Budget.
        </div>
      </div>
      <div className="arrow">
        <FaLongArrowAltRight></FaLongArrowAltRight>
      </div>
      <div className="workflowPart">
        <div className="num">02</div>
        <div className="title">Erstellen der Virtuellen Reise</div>
        <div className="text">
          Schritt für Schritt bauen wir Ihre Virtuelle Schulung. Hierbei
          erstellen wir alle 2D- und 3D-Inhalte und Umgebungen und Programmieren
          deren Zusammenspiel. Mit einem geschulten Blick für Details, um
          lebendige und authentische Lernerlebnisse zu schaffen.
        </div>
      </div>
      <div className="arrow">
        <FaArrowsAltH />
      </div>
      <div className="workflowPart">
        <div className="num">03</div>
        <div className="title">Monitoring und Korrigieren in Workshops</div>
        <div className="text">
          Durch regelmäßige Workshops und Meilensteine stellen wir sicher, dass
          sich das Training zu Ihrer Zufriedenheit Entwickelt. Durch Nutzung von
          Tools wie Microsoft Teams und Miro sind wir bei Anregungen und Fragen
          immer für Sie erreichbar.
        </div>
      </div>
      <div className="arrow">
        <FaLongArrowAltRight />
      </div>
      <div className="workflowPart">
        <div className="num">04</div>
        <div className="title">Schulen für Inbetriebnahme und Nutzung </div>
        <div className="text">
          Gerne unterstützen wir Sie bei der Inbetriebnahme der Hardware, sowie
          beim Aufbau vor Ort. Falls notwendig schulen wir Ihr Personal im
          Umgang mit dem virtuellen Training, damit immer ein VR Experte vor Ort
          ist.
        </div>
      </div>
    </div>
  );
}
