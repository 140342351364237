/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/brille.glb");
  return (
    <group ref={group} {...props} dispose={null} onClick={(e)=>console.log(e)}>
      <mesh
        geometry={nodes.Cube004.geometry}
        material={materials["Plastic.001"]}
      ></mesh>
      <mesh
        geometry={nodes.Cube004_1.geometry}
        material={materials["Headset.001"]}
      />
      <mesh
        geometry={nodes.Cube004_2.geometry}
        material={materials["LinseR.002"]}
      ></mesh>
      <mesh
        geometry={nodes.Cube004_3.geometry}
        material={materials["LinseL.002"]}
      />
    </group>
  );
}

useGLTF.preload("/brille.glb");
