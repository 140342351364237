import React from "react";
import "./Industrie.scss";
import {
  FaArrowsAlt,
  FaClock,
  FaMoneyBill,
  FaPlaneDeparture,
} from "react-icons/fa";
import Layout from "../../atoms/Layout/Layout";
import Advantages from "../../atoms/Advantages/Advantages";
import Workflow from "../../atoms/Workflow/Workflow";
import VRPrices from "../../atoms/VRPrices/VRPrices";
import Footer from "../../atoms/Footer/Footer";
import ContactForm from "../../atoms/ContactForm/ContactForm";
import Spacer from "../../atoms/Spacer/Spacer";
export default function Industrie() {
  return (
    <Layout sub>
      <div className="videoWrapper">
        <video autoPlay loop muted src="/secvr.mp4" />
        <div className="videoOverlay">
          <div className="overlayTitle">
            <div className="orange">Fachkräfte machen</div>
            <div>
              statt <span className="bold">Fachkräfte Mangel!</span>
            </div>
          </div>
          Fähige Mitarbeiter sind die treibende Kraft Ihres Unternehmens.
          <br></br>
          <br></br>
          Lemonverse VR Trainings unterstützen Sie dabei Ihre Mitarbeiter zu
          Fachkräften auszubilden.
          <Spacer h={24}></Spacer>
          <a href="#contact" style={{ maxWidth: 120 }} className="demoButton">
            Mehr erfahren
          </a>
        </div>
      </div>
      <div className="mobileVideoInfos">
        <div className="overlayTitle">
          <div className="orange">Fachkräfte machen</div>
          <div>
            statt <span className="bold">Fachkräfte Mangel!</span>
          </div>
        </div>
        Fähige Mitarbeiter sind die treibende Kraft Ihres Unternehmens.
        <br></br>
        <br></br>
        Lemonverse VR Trainings unterstützen Sie dabei Ihre Mitarbeiter zu
        Fachkräften auszubilden.
      </div>
      <div className="partnerLogoGroup">
        <div className="partnerLogos">
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/wh.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/mondi.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/asbeck.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/lwl.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/lbs.png"></img>
          </div>
        </div>
        <div className="partnerLogos">
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/reach.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/innofact.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/beventum.png"></img>
          </div>
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/sprind.png"></img>
          </div>
          {/*
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/asbeck.png"></img>
          </div>
          */}
          <div className="partnerLogo">
            <img alt="Partner Logo" src="/logos/digihub.png"></img>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="inner">
          <div className="title right">
            <div>
              <span className="bold">Hands-On-Trainings</span> als{" "}
              <span className="bold">als E-Learning</span>
            </div>
            <span className="bold">jederzeit</span> und{" "}
            <span className="orange">Ortsungebunden</span>? Kein Problem.
          </div>
          <div className="line"></div>
          Unsere Custom Software bietet Ihnen die Möglichkeit, Ihre Mitarbeiter
          interaktiv und immersiv zu Schulen.
          <br></br>
          <br></br>
          Nutzen Sie unsere Hands-On VR-Trainings, um spezifische Themen wie
          Maschinenbedienung, Wartung und vieles mehr zu vermitteln.
          <br></br>
          <br></br>
          Gemeinsam mit unseren Kunden entwickeln wir maßgeschneiderte Konzepte
          und Storyboards, und bauen ein realitätsnahes und immersives
          Lernumfeld, das die Lernenden aktiv in den Prozess einbezieht und so
          das Wissen nachhaltig festigt.
          <br></br>
          <br></br>
          <div className="bold">
            Kontaktieren Sie uns noch heute und entdecken Sie eine neue Art des
            Lernens!
          </div>
        </div>
      </div>
      <div className="welcomeBlock">
        <div className="wbInner">
          <div className="right">
            <div className="icons">
              <div className="icon">
                <div className="iconImage">
                  <FaMoneyBill></FaMoneyBill>
                </div>
                <div className="iconText">Spart bis zu 90%</div>
                <div className="iconSubText">
                  im Vergleich zu altmodischen Lösungen
                </div>
              </div>
              <div className="icon">
                <div className="iconImage">
                  <FaClock></FaClock>
                </div>
                <div className="iconText">Über 50% schneller</div>
                <div className="iconSubText">als herkömmlichen Lösungen</div>
              </div>
              <div className="icon">
                <div className="iconImage">
                  <FaArrowsAlt></FaArrowsAlt>
                </div>
                <div className="iconText">40% effizienter</div>
                <div className="iconSubText">
                  als klassischer Präsenzunterricht
                </div>
              </div>
              <div className="icon">
                <div className="iconImage">
                  <FaPlaneDeparture />
                </div>
                <div className="iconText">Keine unnötige Flugreisen</div>
                <div className="iconSubText">
                  im Vergleich zu altmodischen Lösungen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <Advantages></Advantages>
      <div className="section">
        <div className="inner">
          <div className="title right">
            Mitarbeiter <span className="orange">schulen</span> und dabei
            <br></br>den <span className="orange">Fortschritt</span> tracken.
          </div>
          <div className="line"></div>
          <div className="textImageZone reverse">
            <div className="image">
              <img
                alt="two people wearing VR glasses"
                src="/vrtraining.png"
              ></img>
            </div>
            <div className="text">
              <div className="title">
                Virtual Reality Hands-On{" "}
                <span className="orange">Training</span>
              </div>
              Ihre Mitarbeiter setzen die VR Brille auf und finden sich in einer
              VR-Trainingsumgebung wieder, die aus einem Tutorial und
              Hands-On-Trainingsszenarien besteht.
              <br />
              <br></br>
              Innerhalb der Trainingsszenarien, in denen die Mitarbeiter ein
              Erklärvideo anschauen, praktische Übungen durchführen und
              anschließend unter Zeitdruck eine Prüfung absolvieren.
            </div>
          </div>
          <div className="textImageZone ">
            <div className="text">
              <div className="title">
                Dashboard zum{" "}
                <span className="orange">Tracken des Fortschritts</span>
              </div>
              Der Lernfortschritt ihrer Mitarbeiter wird konstant getrackt.
              Hierfür wird ein Dashboard bereitgestellt, auf dem der Fortschritt
              und die Quoten einzelner Mitarbeiter sichtbar sind.<br></br>
              <br></br>Dadurch können die Lernerfolge der Mitarbeiter
              analysiert, nachverfolgt und verbessert werden.
            </div>
            <div className="image">
              <img
                alt="two people standing in front of a presentation"
                src="/dashboard_screen.png"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="section dark">
        <div className="inner">
          <div className="title">
            Lemonverse <span className="bold">Workflow</span>
            <br></br>in der <span className="orange">Projektumsetzung</span>:
          </div>
          <div className="line"></div>
          <Workflow></Workflow>
        </div>
      </div>
      <div className="section grey">
        <div className="inner">
          <div className="title">
            <span className="orange">Preispakete</span> für die Ersellung
            <br></br>
            Ihres <span className="bold">VR Hands On Trainings:</span>
          </div>
          <div className="line"></div>
          <VRPrices></VRPrices>
        </div>
      </div>
      <ContactForm></ContactForm>
      <Footer></Footer>
    </Layout>
  );
}
