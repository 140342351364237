import React from "react";
import Spacer from "../Spacer/Spacer";
import "./VRPrices.scss";

export default function VRPrices() {
  return (
    <div className="VRPrices">
      <div className="priceBox">
        <div className="title">
          Kostenlose<br></br>Beratung
        </div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Gerne beraten wir Sie kostenlos Remote,<br></br>oder kommen mit einem
          Demo Hands On Training bei Ihnen vorbei.
        </div>

        <Spacer h={24}></Spacer>
        <div className="flex1"></div>
        <div className="price">bis 0,00€</div>
        <Spacer h={24}></Spacer>
        <a href="#contact" className="button">Jetzt anfragen</a>
      </div>
      <div className="priceBox">
        <div className="title">Interactive 360°</div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Projektsteuerung
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Lern Konzept
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Dashboard
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Tutorial
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Aufnahmen Bearbeiten und Implementieren der 360° Inhalte
        </div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          2x standalone VR Brille
        </div>
        <Spacer h={24}></Spacer>
        <div className="flex1"></div>
        <div className="price">ab 12.000€</div>
        <Spacer h={24}></Spacer>
        <a href="#contact" className="button">Jetzt anfragen</a>
      </div>
      <div className="priceBox">
        <div className="title">Interactive VR</div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Projektsteuerung
        </div>

        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Lern Konzept
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Dashboard
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Tutorial
        </div>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          Aufnahmen Bearbeiten und Implementieren der 3D Inhalte
        </div>
        <Spacer h={24}></Spacer>
        <div className="bullet">
          +<Spacer w={8}></Spacer>
          2x standalone VR Brille
        </div>
        <Spacer h={24}></Spacer>
        <div className="flex1"></div>
        <div className="price">ab 24.000€</div>
        <Spacer h={24}></Spacer>
        <a href="#contact" className="button">Jetzt anfragen</a>
      </div>
    </div>
  );
}
