import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home/Home";
import Impressum from "./screens/Impressum/Impressum";
import Privacy from "./screens/Privacy/Privacy";
import BookDemo from "./screens/BookDemo/BookDemo";
import Industrie from "./screens/Industrie/Industrie";
import Marktforschung from "./screens/Marktforschung/Marktforschung";
import FFB from "./screens/FFB/FFB";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home></Home>}></Route>
      <Route path="/impressum" element={<Impressum></Impressum>}></Route>
      <Route path="/privacy" element={<Privacy></Privacy>}></Route>
      <Route path="/demo" element={<BookDemo />}></Route>
      <Route path="/industrie" element={<Industrie />}></Route>
      <Route path="/marktforschung" element={<Marktforschung />}></Route>
      <Route path="/project1" element={<FFB />}></Route>
    </Routes>
  );
}

export default App;
